
.btn-facebook,
.btn-facebook:focus,
.btn-facebook.focus {
   color: #3b5998;
}


.btn-facebook:hover,
.btn-facebook:active,
.btn-facebook.active {
   color: #2d4373;
}

.btn-facebook:active:hover,
.btn-facebook.active:hover,
.btn-facebook:active:focus,
.btn-facebook.active:focus {
   color: #23345a;
}

.btn-twitter,
.btn-twitter:focus,
.btn-twitter.focus {
   color: #55acee;
}


.btn-twitter:hover,
.btn-twitter:active,
.btn-twitter.active {
   color: #2795e9;
}

.btn-twitter:active:hover,
.btn-twitter.active:hover,
.btn-twitter:active:focus,
.btn-twitter.active:focus {
   color: #1583d7;
}

.btn-instagram,
.btn-instagram:focus,
.btn-instagram.focus,
.btn-linkedin,
.btn-linkedin:focus,
.btn-linkedin.focus,
.btn-email,
.btn-email:focus,
.btn-email.focus {
   color: #17244E;
}

.btn-instagram:hover,
.btn-instagram:active,
.btn-instagram.active
.btn-linkedin:hover,
.btn-linkedin:active,
.btn-linkedin.active
.btn-email:hover,
.btn-email:active,
.btn-email.active {
   color: #0C1227;
}

.btn-instagram:active:hover,
.btn-instagram.active:hover,
.btn-instagram:active:focus,
.btn-instagram.active:focus
.btn-linkedin:active:hover,
.btn-linkedin.active:hover,
.btn-linkedin:active:focus,
.btn-linkedin.active:focus
.btn-email:active:hover,
.btn-email.active:hover,
.btn-email:active:focus,
.btn-email.active:focus {
   color: #0C1227;
}
//17244E
