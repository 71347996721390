@import "./palette.less";
/*toastr overrides:
  parent toastr classes are not acting right - adding !important
*/
.toast-success {
   background-color: #51A351 !important
}

.toast-error {
   background-color: #BD362F !important
}

.toast-info {
   background-color: #2F96B4 !important
}

.toast-warning {
   background-color: #F89406 !important
}

/* bootstrap overrides */
// .card {
//    //border: 1px solid @color-site-primary;
// }

.card-header {
   color: @color-site-secondary;
   border-bottom: 1px solid @color-site-quinary;
}

.btn-success {
   background-color: #77A163;
   border-color: #6B9358;
}

.nav-tabs .nav-link {
   font-size: 20px;
}

.list-group-item.active.light-header {
   background-color: #5389c3;
   border-color: #5389c3;

   &.success {
      background-color: #d4edda;
      border-color: #c3e6cb;
      color: #155724;
   }
}

.table-hover tbody tr:hover {
   color: #212529;
   background-color: rgba(0,0,0,.09);
}


// ie10 + bootstrap4 overrides
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   // fixes stretched images in cards
   .card-group, .card-deck {
      width: 100%;

      .card {
         display: block;
      }
   }
}


/* ngx-datatable - ViewEncapsulation.None setting causes setting class issue
   - https://stackoverflow.com/questions/47198777/ngx-datatable-cellclass-not-working
   - set classes globally
*/

.ngx-datatable {
   /* REMOVE THIS - just for designing table cells */
   // .datatable-body-cell-label {
   //    // border: solid 1px blue;
   // }

   .btn-group-sm > .btn, .btn-sm {
      //padding: .25rem .5rem;
      font-size: .65rem;
      //line-height: 1.5;
      //border-radius: .2rem;
   }

   .custom() {
      padding-right: 0.3rem !important;
      padding-left: 0.3rem !important;
   }

   .datatable-body-cell.custom {
      .custom();
   }
   //&#pending-returns-grid,
   &#property-plans-grid {
      height: 300px;
      /*.typeDescription {
      width: 120px !important;
      text-align: center;
    }
*/
      .shareOffer,
      .sharePrice,
      .totalAdjustedShareOffer,
      .totalPurchasedShares,
      .totalRepurchasedShares,
      .totalEvergreenShares {
         text-align: right
      }

      .address {
         width: 500px;
      }
   }
   /*&#property-contacts-grid {
      height: 200px;
   }*/
   &#agents-grid {
      height: 400px;
   }

   &#properties-grid {
      height: 400px;

      .address {
         width: 500px !important;
         max-width: 500px !important;
      }


      .name {
         //width: 170px !important;
         .material-icons {
            vertical-align: middle;
            margin-bottom: 0.2em;
         }
      }

      .checklist {
         width: 65px !important;
         text-align: center;
      }

      .edit {
         width: 75px !important;
         text-align: center;
      }

      .offered,
      .scheduled,
      .evergreen,
      .dividends {
         text-align: right
      }

      .dateTime {
         width: 130px !important;
         text-align: center;
      }

      .common {
         width: 120px !important;
         text-align: center;
      }

      .identificationId {
         width: 150px !important;
         text-align: center;
      }

      .agentDisplay {
         width: 250px !important;
      }

      .merchReturnForm {
         width: 300px !important;
      }

      .shortDate {
         width: 100px !important;
         text-align: center;
      }
   }
}

#pending-returns-grid.ngx-datatable .datatable-body {
   .ellipsis {
      font-size: 28px;
      line-height: 14px;
   }
}

.time-zone .ng-dropdown-panel .ng-dropdown-panel-items {
   max-height: 180px !important;
}
