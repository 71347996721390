@import "../utilities/palette.less";
/**********/
/* Errors */
/**********/
.ng-select.ng-valid .ng-select-container,
input.ng-valid[required], input.ng-valid.required,
textarea.ng-valid[required], textarea.ng-valid.required{
   border-left: 5px solid #42A948!important; /* green */
}

.ng-select.ng-invalid .ng-select-container,
input.ng-invalid:not(form),
textarea.ng-invalid:not(form) {
   border-left: 5px solid @color-error !important; /* red */
   /*background-color: #e3c3c5;*/
}

// supporting ng-select dropdowns
/*ng-select.ng-invalid.ng-touched .ng-select-container {
   background-color: #e3c3c5;
   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}*/

.error-msg {
   color: @color-error;
   float: right;
   padding-left: 10px;
   font-size: .85rem
}

.error .ng-select.ng-invalid .ng-select-container,
.error input,
.error textarea {
   background-color: #e3c3c5
}

.error ::-webkit-input-placeholder {
   color: #999
}

.error ::-moz-placeholder {
   color: #999
}

.error :-moz-placeholder {
   color: #999
}

.error :-ms-input-placeholder {
   color: #999
}

.form-control.display-input {
   text-align: center;

   &.no-label {
      margin-top:30px;
   }
}

.swr-form {
  .form-group label,
  .form-group .label,
  .form-group .fake-label {
    font-size: 18px;
  }
}
