@import "fonts/fonts.less";
@import "utilities/palette.less";
@import "utilities/background.less";
@import "utilities/text.less";
@import "utilities/border.less";
@import "components/buttons.less";
@import "components/alerts.less";
@import "components/forms.less";
@import "components/quantity.less";
@import "components/sidebar.less";
@import "components/modals.less";
@import "utilities/various.less";
@import "utilities/social.less";
@import "components/off-canvas.less";
@import "utilities/grid.less";
@import "utilities/third-party-overrides.less";

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
   :root {
      scroll-behavior: smooth;
   }
}

html {
   height:100%;
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
/*aerial-shot-aerial-view.jpg
https://swrealestate.com/wp-content/uploads/2019/05/aerial-photography-aerial-shot-aerial-view-1546166.jpg
*/
body {
   background: url(/assets/images/aerial-shot-aerial-view.jpg) no-repeat center center fixed;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
  height:100%;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: @color-text;
  text-align: left;
  /*background-color: #fff;*/
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

a, a:hover, a:focus {
   color: @color-site-secondary;
   text-decoration: none;
}

.hoverwell:hover {
   background-color: #485563;
   cursor: pointer;
}

[hidden] {
   display: none !important;
}

.splash-image {
  width:480px;

  @media (max-width: 500px) {
    width:350px;
  }
}

.app-page-container {
   height: 100%;
   width: 100%;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
}


.full-page-container {
   display: flex;
   flex-direction: column;
   height: 100%;
   width: 100%;
}

/* Spinner */
.spinner-splash {
  font-size: 300%;
}
.spinner {
   font-size: 300%;
   position: absolute;
   top: 50%;
   left: 50%;
   z-index: 10
}

.fa-spinner {
   -webkit-animation: spin 1000ms infinite linear;
   animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }

   100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
   }
}

@keyframes spin {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }

   100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
   }
}


// body {
//   margin: 0;
//   font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #212529;
//   text-align: left;
//   background-color: #fff;
// }

@media print {
   .d-print-inline {
      display: inline !important;
   }

   .d-print-inline-block {
      display: inline-block !important;
   }

   .d-print-block {
      display: block !important;
   }

   .d-print-grid {
      display: grid !important;
   }

   .d-print-table {
      display: table !important;
   }

   .d-print-table-row {
      display: table-row !important;
   }

   .d-print-table-cell {
      display: table-cell !important;
   }

   .d-print-flex {
      display: flex !important;
   }

   .d-print-inline-flex {
      display: inline-flex !important;
   }

   .d-print-none {
      display: none !important;
   }
}


#wrapper {
   overflow-x: hidden;
}

#sidebar-wrapper {
   /*min-height: 100vh;*/
   width: 15rem; /*4.5rem*/
   margin-left: -15rem; /*-4.5rem*/
   transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
   padding: 0.875rem 1.25rem;
   font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
   width: 15rem; /*4.5rem*/
}



body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
   margin-left: 0;
}

/*#page-content-wrapper {
   min-width: 100vw;
}*/

@media (min-width: 1024px) {
  /* #sidebar-wrapper {
      margin-left: 0;
   }

   #page-content-wrapper {
      min-width: 0;
      width: 100%;
   }*/

   body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
      margin-left: -15rem; /*-4.5rem*/
   }
}

