.off-canvas-active {
  overflow: hidden;
}

.screen-overlay {
  width: 0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity .2s linear, visibility .1s, width 1s ease-in;

  &.show {
    transition: opacity .5s ease, width 0s;
    opacity: 1;
    width: 100%;
    visibility: visible;
  }
}

.off-canvas {
  width: 280px;
  visibility: hidden;
  transform: translateX(-100%);
  transition: all .2s;
  border-radius: 0;
  box-shadow: 0 5px 10px rgba(0,0,0, .2);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1200;
  background-color: #fff;
  overflow-y: hidden;/*scroll*/
  overflow-x: hidden;

  &.off-canvas-right {
    right: 0;
    left: auto;
    transform: translateX(100%);
  }

  &.show {
    visibility: visible;
    transform: translateX(0);
    transition: transform .2s;
  }

  // .btn-close {
  //   position: absolute;
  //   right: 15px;
  //   top: 15px;
  // }
}
