@import "palette.less";
button.close {
   outline:none;
}


legend {
  color: @color-site-tertiary;
  border-bottom: 1px solid @color-site-primary;
}


.fake-label {
   display: inline-block;
   margin-bottom: .5rem;

   &::before,
   &::after {
      box-sizing: border-box;
   }
}

.question {
  color: @color-text;
  cursor: pointer;
  font-size: 18px;
}

.outer-shadow {
  -moz-box-shadow: 3px 3px 3px #666;
  -webkit-box-shadow: 3px 3px 3px #666;
  box-shadow: 3px 3px 3px #666;
}

.inner-shadow {
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;

}

.testing {
  border: solid 2px green;

  .row {
    border: dotted 1px red;
    margin-top: 5px;
    margin-bottom: 5px;

    div[class^="col"] {
      border: dotted 1px blue;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

/* for use to line up buttons with other
   form-control elements
*/
.col-button {
  padding-top: 31px;
}

.col-middle-text-no-label {
  padding-top: 38px;
}

.alert-site-primary {
  color: #1f3232;
  background-color: #e9a967;
  border-color: #db7c14;
  //opacity: .65;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;

  &.small {
     font-size: 1rem;
  }
}

@media (min-width: 1024px) {

  .my-modal-md .modal-dialog {
    width: 75%;
    max-width: 800px;
  }
}

@media (min-width: 768px) {
  .my-modal-lg .modal-dialog {
    width: 95%;
    max-width: 1000px;
  }

  .my-modal-md .modal-dialog {
    width: 80%;
    max-width: 800px;
  }
}


//https: //codepen.io/supah/pen/BjYLdW
/*html, body {
  height: 100%;
  background-image: linear-gradient(-105deg, #009acc, #363795);
}
*/
svg.button-spinner {
  animation: rotate 1s linear infinite;
  z-index: 2;
  /*position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;*/
  width: 20px;
  height: 20px;
  margin-right: 10px;

  & .path {
    stroke: black;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.line {
  height: 2px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.list-group-striped .list-group-item:nth-child(odd) {
  background: #EDEDED;
}

.list-group-striped a.list-group-item:hover,
.list-group-striped button.list-group-item:hover,
.list-group-striped .list-group-item:hover,
.list-group-striped a.list-group-item:focus,
.list-group-striped button.list-group-item:focus
.list-group-striped .list-group-item:focus {
  color: #555;
  text-decoration: none;
  background-color: #E0E0E0;
}

.background-box-rounded {
   border-radius: 0.25rem !important;
}
.background-box-opaque-rounded {
   border-radius: 0.25rem !important;
   background-color: rgba(255,255,255,0.5);
}
.processing-gif {
   width:60px;
}

/*preloading spinner*/
.preloading-spinnner {
   position: fixed;
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 0;
   left: 0;
   background: rgba(0, 0, 0, 0.32);
   z-index: 2000;

   .spinner_aj0A {
      transform-origin: center;
      animation: spinner_KYSC .75s infinite linear
   }
}

@keyframes spinner_KYSC {
   100% {
      transform: rotate(360deg)
   }
}
