.modal-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
 
    .add-agent::before {
       content: "\f234";
       font-family: "Font Awesome 5 Free";
       padding-right: .35em;
       font-weight: 900 !important;
    }
 
    .agents::before {
       content: "\f500";
       font-family: "Font Awesome 5 Free";
       padding-right: .35em;
       font-weight: 900 !important;
    }
 }
 