@import "palette.less";

.cards {
   display: grid;
   /*grid-template-columns: repeat(3, 1fr);*/
   /*grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));*/
   /*mobile*/
   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
   grid-auto-rows: auto;
   grid-gap: 1rem;

   .card {
      border: 1px solid #cdcbcb; /*  cdcbcb e7e7e7 */
      border-radius: 4px;
      padding: .5rem;
      background-color: #e4eadc40; /* e4eadc87 e4eadc87 ffefd542 6b8e2324 6b8e2342 e9eae9 d8e6d8 e0ffff73 fafad230;*/

      .thumbnail img {
         fill: #c7c4c4;
         height: 135px;
         opacity: .25;
         padding: 1.5rem;
      }

      .card-content {
         font-size: 1rem;
         display: flex;
         -webkit-box-orient: vertical;
         -webkit-box-direction: normal;
         -ms-flex-direction: column;
         flex-direction: column;
         -webkit-box-flex: 1;
         -ms-flex: 1;
         flex: 1;


         .status {
            font-size: .75rem;
            text-transform: uppercase;
         }

         .status {
            position: absolute;
            bottom: 20px;
            left: 20px;
            color: black;
            background-color: #E1F5FE;
            border: solid 1px gray;
            padding: 10px 15px;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;

            &.submitted,
            &.complete,
            &.cancelled {
               color: white;
            }

            &.submitted {
               background-color: @color-site-primary-alt;
               border-color: darken(@color-site-primary-alt, 6%);
            }

            &.complete {
               background-color: @color-site-primary;
               border-color: darken(@color-site-primary, 6%);
            }

            &.cancelled {
               background-color: firebrick;
               border-color: darken(firebrick, 6%);
            }
         }
      }

      footer {
         border-top: 2px solid #cdcdcd; /* cdcdcd e7e7e7 */
         margin: .5rem 0 0;
         min-height: 30px;
         font-size: 1rem;

         .post-meta {
            margin-top: 1.25rem;
            padding-top: 4px;
            display: flex;
            justify-content: space-between;
         }

         .edit {
            color: #72A130;

            > i {
               font-size: 1.35rem;
            }
         }
      }
   }
}






  .panel.card-panel .panel-header {
      background-color: #ffffff;
      font-size: .75rem;
      font-weight: 400;
      height: 25px !important;
  }

  .panel.card-panel .panel-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }





  .category__01 {
    background-color: #50c6db;
  }

  .category__02 {
    background-color: #5d5e5e;
  }

  .category__03 {
    background-color: #51bb7b;
  }

  .category__04 {
    background-color: #f47820;
  }

  .category__05 {
    background-color: #8350a0;
  }

