
.quantity-control {
   width: 105px;

   .form-control,
   .form-control[readonly],
   .form-control:focus {
      background-color: #fff !important;  
      border-color: rgb(206, 212, 218) !important;
      outline: 0 !important;
      box-shadow: none !important;
   }

   .input-group-text {
      color: #e67e22 !important;
      font-weight: bold;
   }
}
