@import "palette.less";
.border-site-primary {
   border-color: @color-site-primary !important;
}

.ps-5 {
   padding-left: 4rem !important;
}

.border-site-primary {
   border-color: @color-site-primary;
}

.border-site-secondary {
   border-color: 1px solid @color-site-secondary;
}

.border-site-tertiary {
   border-color: 1px solid @color-site-tertiary;
}

.border-site-quaternary {
   border-color: 1px solid @color-site-quaternary;
}

